import React from "react"
import { SocialIcon } from "react-social-icons"
import Image from "../components/image"
import SEO from "../components/seo"

function IndexPage() {
  return (
    <>
      <SEO title="Home" />
      <div className="page-container bg-acquamarine bg-opacity-25 h-full flex items-center phone:p-0 font-body">
        <div className="rounded-xl shadow-2xl mx-auto h-auto w-1/4 phone:w-auto bg-white">
          <div className="flex flex-col px-6 py-6 phone:p-4">
            <div className="w-2/6 mx-auto">
              {/* <Image className="rounded-xl" /> */}
              <Image />
            </div>
            <hr className="my-4 phone:my-2" />
            <div className="text-center">
              <h1 className="text-2xl phone:text-lg">
                Ciao, sono <span className="font-bold">Matteo</span>!
              </h1>
            </div>
            {/* <hr className="my-4 phone:my-2" /> */}
            <div className="mt-2">
              <h1 className="text-center font-bold underline phone:text-sm">
                Full Stack Developer
              </h1>
            </div>
            <hr className="my-4 phone:my-2" />
            <div className="h-full flex-grow px-2">
              <div className="m4-4">
                <h1 className="text-base phone:text-sm font-bold">
                  <span className="mr-2">Linguaggi che conosco:</span>
                </h1>
                <ul className="list-disc px-8 phone:px-6 phone:text-sm">
                  <li>Java && Groovy</li>
                  <li>Javascript && Typescript</li>
                  <li>SQL</li>
                </ul>
              </div>
              <div className="mt-4">
                <h1 className="text-base phone:text-sm font-bold">
                  <span className="mr-2">Tecnologie che utilizzo:</span>
                </h1>
                <ul className="list-disc px-8 phone:px-6 phone:text-sm">
                  <li>Grails</li>
                  <li>Spring Boot</li>
                  <li>React</li>
                  <li>Angular</li>
                </ul>
              </div>
            </div>
            <hr className="my-4 phone:my-2" />
            <div className="flex justify-between">
              <div className="align-middle my-auto">
                {/* <Link to="/progetti">
                  Progetti
                </Link> */}
              </div>
              <div>
                <SocialIcon
                  className="hover:shadow-xl rounded-full"
                  network="linkedin"
                  key="linkedin-profile-url"
                  url="https://www.linkedin.com/in/matteociasco"
                  label="Il mio profilo Linkedin"
                  title="Il mio profilo Linkedin"
                  target="_blank"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default IndexPage
